.containers {
  display: flex;
  flex-direction: column;
}

.header {

}

.main {
  //height: 100%;
  //overflow: hidden;
  //padding-bottom: 22px;
  //background: blanchedalmond;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > div {
    align-items: flex-end;
  }

  :global(.qrcode) {
    position: relative;
    background: #F8F8F8;
    border-radius: 8px;
    padding: 12px;
    width: 124px;
    height: 130px;

    :global(.print) {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../../../public/images/print.svg") center no-repeat;
      border-radius: 50%;
      &:hover {
        cursor: pointer;
        background: url("../../../public/images/print.svg") center no-repeat, #ffd5b3;
      }
    }
  }
}

.addEvent {
  padding: 0 8px 4px;
  width: 100%;
  display: block;

  :global(span.ant-input-group) {
    & > input {
      border: 0;
      padding: 10px;
      background: rgba(241, 241, 241, 0.5);
      border-radius: 8px;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      &:focus {
        box-shadow: 0 0 0 1px #F1F1F1;
        box-sizing: border-box;
        background: #FFFFFF;
      }
    }
    & > button {
      height: 42px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
